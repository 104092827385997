import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import './licitaciones.css';
 
const Licitaciones = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
  return (
    <div className='container licitaciones-container'>
        <div className='row'>
            <div className='col-12'>
                <div className='licitaciones_title'>
                    <h1>Licitaciones Públicas Nacionales</h1>
                </div>
            </div>
        </div>

        <div className='row'>

          <div class="accordion" id="accordionExample">

         <div class="accordion-item">
              <h2 class="accordion-header" id="headingOneCECULTAH-LA-11-2024"><button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOneCECULTAH-LA-11-2024" aria-expanded="false" aria-controls="collapseOneCECULTAH-LA-11-2024">CECULTAH-LA-11-2024 | Servicio de Conservación y Mantenimiento Menor de Inmuebles a Cargo del CECULTAH: Cuartel del Arte, Centro Cultural del Real del Monte y Centro Cultural del Ferrocarril.</button></h2>
              <div id="collapseOneCECULTAH-LA-11-2024" class="accordion-collapse collapse" aria-labelledby="headingOneCECULTAH-LA-11-2024" data-bs-parent="#accordionExample">
                 <div class="accordion-body">
                    <ul class="list-group">
                        <li class="list-group-item">Convocatoria<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/procedimientos/CECULTAH-LA-11-2024/CECULTAH-LA-11-2024_Convocatoria.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                         <li class="list-group-item">Bases<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/procedimientos/CECULTAH-LA-11-2024/CECULTAH-LA-11-2024_Bases.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                        <li class="list-group-item">Junta de Aclaraciones<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/procedimientos/CECULTAH-LA-11-2024/CECULTAH-LA-11-2024_Junta de aclaraciones1.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                        <li class="list-group-item">Apertura de Propuestas<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/procedimientos/CECULTAH-LA-11-2024/CECULTAH-LA-11-2024_Apertura de propuestas.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                        {/*<li class="list-group-item">Fallo<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/procedimientos/CECULTAH-LA-11-2024/CECULTAH-LA-11-2024 (Fallo).pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a>*/}
                   </ul>
                 </div>
              </div>
           </div>

         <div class="accordion-item">
              <h2 class="accordion-header" id="headingOneCECULTAH-LA-10-2024"><button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOneCECULTAH-LA-10-2024" aria-expanded="false" aria-controls="collapseOneCECULTAH-LA-10-2024">CECULTAH-LA-10-2024 | Servicio de Mantenimiento de Maquinaria y Equipo de los Inmuebles a cargo del CECULTAH; Cuartel del Arte, Biblioteca Ricardo Garibay y Centro Cultural del Ferrocarril.</button></h2>
              <div id="collapseOneCECULTAH-LA-10-2024" class="accordion-collapse collapse" aria-labelledby="headingOneCECULTAH-LA-10-2024" data-bs-parent="#accordionExample">
                 <div class="accordion-body">
                    <ul class="list-group">
                        <li class="list-group-item">Convocatoria<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/procedimientos/CECULTAH-LA-10-2024/CECULTAH-LA-10-2024_Convocatoria.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                        <li class="list-group-item">Bases<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/procedimientos/CECULTAH-LA-10-2024/CECULTAH-LA-10-2024_Bases.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                        <li class="list-group-item">Junta de Aclaraciones<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/procedimientos/CECULTAH-LA-10-2024/CECULTAH-LA-10-2024_Junta de aclaraciones1.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                        <li class="list-group-item">Apertura de Propuestas<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/procedimientos/CECULTAH-LA-10-2024/CECULTAH-LA-10-2024 (Apertura de propuestas).pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                        {/*<li class="list-group-item">Fallo<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/procedimientos/CECULTAH-LA-10-2024/CECULTAH-LA-10-2024 (Fallo).pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a>*/}
                   </ul>
                 </div>
              </div>
           </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOneCECULTAH-LA-09-2024"><button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOneCECULTAH-LA-09-2024" aria-expanded="false" aria-controls="collapseOneCECULTAH-LA-08-2024">CECULTAH-LA-09-2024 | SEGUNDO PROCEDIMIENTO Servicio de Limpieza y Manejo de Desechos (Contratación del Servicio de Fumigación a los inmuebles que se encuentran a cargo del Consejo Estatal para la Cultura y las Artes de Hidalgo (CECULTAH))</button></h2>
              <div id="collapseOneCECULTAH-LA-09-2024" class="accordion-collapse collapse" aria-labelledby="headingOneCECULTAH-LA-09-2024" data-bs-parent="#accordionExample">
                 <div class="accordion-body">
                    <ul class="list-group">
                        <li class="list-group-item">Convocatoria<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/procedimientos/CECULTAH-LA-09-2024/CECULTAH-LA-09-2024 (Convocatoria).pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                        <li class="list-group-item">Bases<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/procedimientos/CECULTAH-LA-09-2024/CECULTAH-LA-09-2024 SEGUNDO PROCEDIMIENTO (Bases).pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                        <li class="list-group-item">Junta de Aclaraciones<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/procedimientos/CECULTAH-LA-09-2024/CECULTAH-LA-09-2024 (Junta de aclaraciones).pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                        <li class="list-group-item">Apertura de Propuestas<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/procedimientos/CECULTAH-LA-09-2024/CECULTAH-LA-09-2024 (Apertura de propuestas).pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                        <li class="list-group-item">Fallo<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/procedimientos/CECULTAH-LA-09-2024/CECULTAH-LA-09-2024 (Fallo).pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                       {/*<li class="list-group-item">Acta de anulacion<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/procedimientos/CECULTAH-LA-05-2023/CECULTAH-LA-05-2023-ActaDeAnulacion.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>*/}
                    </ul>
                 </div>
              </div>
           </div>

          <div class="accordion-item">
              <h2 class="accordion-header" id="headingOneCECULTAH-LA-08-2024"><button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOneCECULTAH-LA-08-2024" aria-expanded="false" aria-controls="collapseOneCECULTAH-LA-08-2024">CECULTAH-LA-08-2024 | Servicio de Limpieza y Manejo de Desechos (Contratación del Servicio de Fumigación a los inmuebles que se encuentran a cargo del Consejo Estatal para la Cultura y las Artes de Hidalgo (CECULTAH))</button></h2>
              <div id="collapseOneCECULTAH-LA-08-2024" class="accordion-collapse collapse" aria-labelledby="headingOneCECULTAH-LA-08-2024" data-bs-parent="#accordionExample">
                 <div class="accordion-body">
                    <ul class="list-group">
                        <li class="list-group-item">Convocatoria<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/procedimientos/CECULTAH-LA-08-2024/CECULTAH-LA-08-2024 (Convocatoria).pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                        <li class="list-group-item">Bases<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/procedimientos/CECULTAH-LA-08-2024/CECULTAH-LA-08-2024 (Bases).pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                        <li class="list-group-item">Junta de Aclaraciones<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/procedimientos/CECULTAH-LA-08-2024/CECULTAH-LA-08-2024-Junta de Aclaraciones.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                        <li class="list-group-item">Apertura de Propuestas<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/procedimientos/CECULTAH-LA-08-2024/CECULTAH-LA-08-2024 (Apertura de Propuestas).pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                        {/*<li class="list-group-item">Fallo<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/procedimientos/CECULTAH-LA-07-2024/CECULTAH-LA-07-2024-Fallo.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                       <li class="list-group-item">Acta de anulacion<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/procedimientos/CECULTAH-LA-05-2023/CECULTAH-LA-05-2023-ActaDeAnulacion.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>*/}
                    </ul>
                 </div>
              </div>
           </div>

          <div class="accordion-item">
              <h2 class="accordion-header" id="headingOneCECULTAH-LA-07-2024"><button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOneCECULTAH-LA-07-2024" aria-expanded="false" aria-controls="collapseOneCECULTAH-LA-07-2024">CECULTAH-LA-07-2024 | Contratación de Seguros</button></h2>
              <div id="collapseOneCECULTAH-LA-07-2024" class="accordion-collapse collapse" aria-labelledby="headingOneCECULTAH-LA-07-2024" data-bs-parent="#accordionExample">
                 <div class="accordion-body">
                    <ul class="list-group">
                       <li class="list-group-item">Convocatoria<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/procedimientos/CECULTAH-LA-07-2024/CECULTAH-LA-07-2024-Convocatoria POEH.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                       <li class="list-group-item">Bases<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/procedimientos/CECULTAH-LA-07-2024/CECULTAH-LA-07-2024-Bases.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                       <li class="list-group-item">Junta de Aclaraciones<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024//procedimientos/CECULTAH-LA-07-2024/CECULTAH-LA-07-2024-Junta de Aclaraciones.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                       <li class="list-group-item">Apertura de Propuestas<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/procedimientos/CECULTAH-LA-07-2024/CECULTAH-LA-07-2024-Apertura de propuestas.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                        <li class="list-group-item">Fallo<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/procedimientos/CECULTAH-LA-07-2024/CECULTAH-LA-07-2024-Fallo.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                       {/*  <li class="list-group-item">Acta de anulacion<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/procedimientos/CECULTAH-LA-05-2023/CECULTAH-LA-05-2023-ActaDeAnulacion.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>*/}
                    </ul>
                 </div>
              </div>
           </div>

          <div class="accordion-item">
              <h2 class="accordion-header" id="headingOneCECULTAH-LA-06-2024"><button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOneCECULTAH-LA-06-2024" aria-expanded="false" aria-controls="collapseOneCECULTAH-LA-06-2024">CECULTAH-LA-06-2024 | Segundo Procedimiento - Contratación del Servicio de mantenimiento a extintores de los espacios del Consejo Estatal para la Cultura y las Artes de Hidalgo (CECULTAH) (2da.)</button></h2>
              <div id="collapseOneCECULTAH-LA-06-2024" class="accordion-collapse collapse" aria-labelledby="headingOneCECULTAH-LA-06-2024" data-bs-parent="#accordionExample">
                 <div class="accordion-body">
                    <ul class="list-group">
                       <li class="list-group-item">Convocatoria<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/procedimientos/CECULTAH-LA-06-2024/CECULTAH-LA-06-2024-Publcacion POEH.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                       <li class="list-group-item">Bases<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/procedimientos/CECULTAH-LA-06-2024/CECULTAH-LA-06-2024-Bases.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                       <li class="list-group-item">Junta de Aclaraciones<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024//procedimientos/CECULTAH-LA-06-2024/CECULTAH-LA-06-2024-Junta Aclaraciones.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                       <li class="list-group-item">Apertura de Propuestas<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/procedimientos/CECULTAH-LA-06-2024/CECULTAH-LA-06-2024-Apertura de propuestas.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                       {/*<li class="list-group-item">Acta de anulacion<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/procedimientos/CECULTAH-LA-05-2023/CECULTAH-LA-05-2023-ActaDeAnulacion.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>*/}
                    </ul>
                 </div>
              </div>
           </div>

          <div class="accordion-item">
              <h2 class="accordion-header" id="headingOneCECULTAH-LA-05-2024"><button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOneCECULTAH-LA-05-2024" aria-expanded="false" aria-controls="collapseOneCECULTAH-LA-05-2024">CECULTAH-LA-05-2024 | Segundo Procedimiento - Adquisición de material de oficina (2da.)</button></h2>
              <div id="collapseOneCECULTAH-LA-05-2024" class="accordion-collapse collapse" aria-labelledby="headingOneCECULTAH-LA-05-2024" data-bs-parent="#accordionExample">
                 <div class="accordion-body">
                    <ul class="list-group">
                       <li class="list-group-item">Convocatoria<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/procedimientos/CECULTAH-LA-05-2024/CECULTAH-LA-05-2024-Publcacion POEH.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                       <li class="list-group-item">Bases<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/procedimientos/CECULTAH-LA-05-2024/CECULTAH-LA-05-2024-Bases.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                       <li class="list-group-item">Junta de Aclaraciones<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024//procedimientos/CECULTAH-LA-05-2024/CECULTAH-LA-05-2024-Junta Aclaraciones.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                       <li class="list-group-item">Apertura de Propuestas<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/procedimientos/CECULTAH-LA-05-2024/CECULTAH-LA-05-2024-Apertura de propuestas.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                       <li class="list-group-item">Fallo<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/procedimientos/CECULTAH-LA-05-2024/CECULTAH-LA-05-2024-Fallo.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                       {/*<li class="list-group-item">Acta de anulacion<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/procedimientos/CECULTAH-LA-05-2023/CECULTAH-LA-05-2023-ActaDeAnulacion.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>*/}
                    </ul>
                 </div>
              </div>
           </div>

          <div class="accordion-item">
              <h2 class="accordion-header" id="headingOneCECULTAH-LA-04-2024"><button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOneCECULTAH-LA-04-2024" aria-expanded="false" aria-controls="collapseOneCECULTAH-LA-04-2024">CECULTAH-LA-04-2024 | Contratación del Servicio de mantenimiento a extintores de los espacios del Consejo Estatal para la Cultura y las Artes de Hidalgo</button></h2>
              <div id="collapseOneCECULTAH-LA-04-2024" class="accordion-collapse collapse" aria-labelledby="headingOneCECULTAH-LA-04-2024" data-bs-parent="#accordionExample">
                 <div class="accordion-body">
                    <ul class="list-group">
                       <li class="list-group-item">Convocatoria<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/procedimientos/CECULTAH-LA-04-2024/CECULTAH-LA-04-2024-Convocatoria.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                       <li class="list-group-item">Bases<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/procedimientos/CECULTAH-LA-04-2024/CECULTAH-LA-04-2024-Bases.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                       <li class="list-group-item">Junta de Aclaraciones<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/procedimientos/CECULTAH-LA-04-2024/CECULTAH-LA-04-2024-Junta de Aclaraciones.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                       <li class="list-group-item">Apertura de Propuestas<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/procedimientos/CECULTAH-LA-04-2024/CECULTAH-LA-04-2024-Apertura de propuestas.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                       {/*<li class="list-group-item">Fallo<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/procedimientos/CECULTAH-LA-04-2024/CECULTAH-LA-04-2024-ActaDeFallo.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>*/}
                    </ul>
                 </div>
              </div>
          </div>

          <div class="accordion-item">
              <h2 class="accordion-header" id="headingOneCECULTAH-LA-03-2024"><button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOneCECULTAH-LA-03-2024" aria-expanded="false" aria-controls="collapseOneCECULTAH-LA-03-2024">CECULTAH-LA-03-2024 | Adquisición de consumibles para bienes informáticos</button></h2>
              <div id="collapseOneCECULTAH-LA-03-2024" class="accordion-collapse collapse" aria-labelledby="headingOneCECULTAH-LA-03-2024" data-bs-parent="#accordionExample">
                 <div class="accordion-body">
                    <ul class="list-group">
                       <li class="list-group-item">Convocatoria<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/procedimientos/CECULTAH-LA-03-2024/CECULTAH-LA-03-2024-Convocatoria.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                       <li class="list-group-item">Bases<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/procedimientos/CECULTAH-LA-03-2024/CECULTAH-LA-03-2024-Bases.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                       <li class="list-group-item">Junta de Aclaraciones<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/procedimientos/CECULTAH-LA-03-2024/CECULTAH-LA-03-2024-Junta de Aclaraciones.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                       <li class="list-group-item">Apertura de Propuestas<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/procedimientos/CECULTAH-LA-03-2024/CECULTAH-LA-03-2024-Apertura de propuestas.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                       <li class="list-group-item">Fallo<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/procedimientos/CECULTAH-LA-03-2024/CECULTAH-LA-03-2024-Fallo.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    </ul>
                 </div>
              </div>
          </div>
          
          <div class="accordion-item">
              <h2 class="accordion-header" id="headingOneCECULTAH-LA-02-2024"><button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOneCECULTAH-LA-02-2024" aria-expanded="false" aria-controls="collapseOneCECULTAH-LA-02-2024">CECULTAH-LA-02-2024 | Adquisición de material de oficina</button></h2>
              <div id="collapseOneCECULTAH-LA-02-2024" class="accordion-collapse collapse" aria-labelledby="headingOneCECULTAH-LA-02-2024" data-bs-parent="#accordionExample">
                 <div class="accordion-body">
                    <ul class="list-group">
                       <li class="list-group-item">Convocatoria<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/procedimientos/CECULTAH-LA-02-2024/CECULTAH-LA-02-2024-Convocatoria.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                       <li class="list-group-item">Bases<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/procedimientos/CECULTAH-LA-02-2024/CECULTAH-LA-02-2024-Bases.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                       <li class="list-group-item">Junta de Aclaraciones<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/procedimientos/CECULTAH-LA-02-2024/CECULTAH-LA-02-2024-Junta de Aclaraciones.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                       <li class="list-group-item">Apertura de Propuestas<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/procedimientos/CECULTAH-LA-02-2024/CECULTAH-LA-02-2024-Apertura de propuestas.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                       <li class="list-group-item">Fallo<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/procedimientos/CECULTAH-LA-02-2024/CECULTAH-LA-02-2024-Fallo.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    </ul>
                 </div>
              </div>
          </div>

          <div class="accordion-item">
              <h2 class="accordion-header" id="headingOneCECULTAH-LA-01-2024"><button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOneCECULTAH-LA-01-2024" aria-expanded="false" aria-controls="collapseOneCECULTAH-LA-01-2024">CECULTAH-LA-01-2024 | Adquisición de material de limpieza</button></h2>
              <div id="collapseOneCECULTAH-LA-01-2024" class="accordion-collapse collapse" aria-labelledby="headingOneCECULTAH-LA-01-2024" data-bs-parent="#accordionExample">
                 <div class="accordion-body">
                    <ul class="list-group">
                       <li class="list-group-item">Convocatoria<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/procedimientos/CECULTAH-LA-01-2024/CECULTAH-LA-01-2024-Convocatoria.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                       <li class="list-group-item">Bases<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/procedimientos/CECULTAH-LA-01-2024/CECULTAH-LA-01-2024-Bases.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                       <li class="list-group-item">Junta de Aclaraciones<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/procedimientos/CECULTAH-LA-01-2024/CECULTAH-LA-01-2024-Junta de Aclaraciones.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                       <li class="list-group-item">Apertura de Propuestas<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/procedimientos/CECULTAH-LA-01-2024/CECULTAH-LA-01-2024-Apertura de propuestas.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                       <li class="list-group-item">Fallo<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/procedimientos/CECULTAH-LA-01-2024/CECULTAH-LA-01-2024-Fallo.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    </ul>
                 </div>
              </div>
          </div>

          {/*<div class="accordion-item">
            <h2 class="accordion-header" id="headingOneCECULTAH-OP-01-2023"><button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOneCECULTAH-OP-01-2023" aria-expanded="false" aria-controls="collapseOneCECULTAH-OP-01-2023">CECULTAH-OP-01-2023 | REHABILITACIÓN Y MANTENIMIENTO DEL CENTRO DE LAS ARTES DE HIDALGO</button></h2>
            <div id="collapseOneCECULTAH-OP-01-2023" class="accordion-collapse collapse" aria-labelledby="headingOneCECULTAH-OP-01-2023" data-bs-parent="#accordionExample">
               <div class="accordion-body">
                  <ul class="list-group">
                     <li class="list-group-item">Convocatoria<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/procedimientos/CECULTAH-OP-01-2023/CECULTAH-OP-01-2023-Convocatoria.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                     <li class="list-group-item">Bases<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/procedimientos/CECULTAH-OP-01-2023/CECULTAH-OP-01-2023-Bases.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                     <li class="list-group-item">Junta de Aclaraciones<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/procedimientos/CECULTAH-OP-01-2023/CECULTAH-OP-01-2023_Junta de Aclaraciones.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                     <li class="list-group-item">Apertura de Propuestas<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/procedimientos/CECULTAH-OP-01-2023/CECULTAH-OP-01-2023-AperturaPropuestas.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>                  
                     <li class="list-group-item">Fallo<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/procedimientos/CECULTAH-OP-01-2023/CECULTAH-OP-01-2023-ActaFallo.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                  </ul>
               </div>
            </div>
          </div>

          <div class="accordion-item">
            <h2 class="accordion-header" id="headingOneCECULTAH-LA-20-2023"><button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOneCECULTAH-LA-20-2023" aria-expanded="false" aria-controls="collapseOneCECULTAH-LA-20-2023">CECULTAH-LA-20-2023 | ADQUISICIÓN DE INSTRUMENTOS MUSICALES”</button></h2>
            <div id="collapseOneCECULTAH-LA-20-2023" class="accordion-collapse collapse" aria-labelledby="headingOneCECULTAH-LA-20-2023" data-bs-parent="#accordionExample">
               <div class="accordion-body">
                  <ul class="list-group">
                     <li class="list-group-item">Convocatoria<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/procedimientos/CECULTAH-LA-20-2023/CECULTAH-LA-20-2023-Convocatoria.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                     <li class="list-group-item">Bases<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/procedimientos/CECULTAH-LA-20-2023/CECULTAH-LA-20-2023-Bases.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                     <li class="list-group-item">Junta de Aclaraciones<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/procedimientos/CECULTAH-LA-20-2023/CECULTAH-LA-20-2023_Junta de Aclaraciones.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                     <li class="list-group-item">Apertura de Propuestas<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/procedimientos/CECULTAH-LA-20-2023/CECULTAH-LA-20-2023-AperturaPropuestas.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>                  
                     <li class="list-group-item">Fallo<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/procedimientos/CECULTAH-LA-20-2023/CECULTAH-LA-20-2023-ActaFallo.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                  </ul>
               </div>
            </div>
          </div>

          <div class="accordion-item">
            <h2 class="accordion-header" id="headingOneCECULTAH-LA-19-2023"><button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOneCECULTAH-LA-19-2023" aria-expanded="false" aria-controls="collapseOneCECULTAH-LA-19-2023">CECULTAH-LA-19-2023 (SEGUNDO PROCEDIMIENTO) | SERVICIO INTEGRAL PARA LA INSTALACIÓN, MONTAJE Y PRODUCCIÓN DE EXPOSICIONES DE ARTES VISUALES EN RECINTOS CULTURALES DEL CECULTAH”</button></h2>
            <div id="collapseOneCECULTAH-LA-19-2023" class="accordion-collapse collapse" aria-labelledby="headingOneCECULTAH-LA-19-2023" data-bs-parent="#accordionExample">
               <div class="accordion-body">
                  <ul class="list-group">
                     <li class="list-group-item">Convocatoria<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/procedimientos/CECULTAH-LA-19-2023/CECULTAH-LA-19-2023-Convocatoria.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                     <li class="list-group-item">Bases<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/procedimientos/CECULTAH-LA-19-2023/CECULTAH-LA-19-2023-Bases.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                     <li class="list-group-item">Junta de Aclaraciones<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/procedimientos/CECULTAH-LA-19-2023/CECULTAH-LA-19-2023_Junta de Aclaraciones.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                     <li class="list-group-item">Apertura de Propuestas<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/procedimientos/CECULTAH-LA-19-2023/CECULTAH-LA-19-2023-AperturaPropuestas.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>                  
                     <li class="list-group-item">Fallo<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/procedimientos/CECULTAH-LA-19-2023/CECULTAH-LA-19-2023-ActaFallo.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                  </ul>
               </div>
            </div>
          </div>

          <div class="accordion-item">
            <h2 class="accordion-header" id="headingOneCECULTAH-LA-18-2023"><button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOneCECULTAH-LA-18-2023" aria-expanded="false" aria-controls="collapseOneCECULTAH-LA-18-2023">CECULTAH-LA-18-2023 (SEGUNDO PROCEDIMIENTO) | ADQUISICIÓN DE INSTRUMENTOS MUSICALES PARA LA BANDA SINFÓNICA DEL ESTADO DE HIDALGO”</button></h2>
            <div id="collapseOneCECULTAH-LA-18-2023" class="accordion-collapse collapse" aria-labelledby="headingOneCECULTAH-LA-18-2023" data-bs-parent="#accordionExample">
               <div class="accordion-body">
                  <ul class="list-group">
                     <li class="list-group-item">Convocatoria<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/procedimientos/CECULTAH-LA-18-2023/CECULTAH-LA-18-2023-Convocatoria.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                     <li class="list-group-item">Bases<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/procedimientos/CECULTAH-LA-18-2023/CECULTAH-LA-18-2023-Bases.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                     <li class="list-group-item">Junta de Aclaraciones<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/procedimientos/CECULTAH-LA-18-2023/CECULTAH-LA-18-2023_Junta de Aclaraciones.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                     <li class="list-group-item">Apertura de Propuestas<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/procedimientos/CECULTAH-LA-18-2023/CECULTAH-LA-18-2023-AperturaPropuestas.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>                  
                     <li class="list-group-item">Fallo<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/procedimientos/CECULTAH-LA-18-2023/CECULTAH-LA-18-2023-ActaFallo.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                  </ul>
               </div>
            </div>
          </div>

          <div class="accordion-item">
            <h2 class="accordion-header" id="headingOneCECULTAH-LA-15-2023"><button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOneCECULTAH-LA-15-2023" aria-expanded="false" aria-controls="collapseOneCECULTAH-LA-15-2023">CECULTAH-LA-15-2023 | PROYECTO ESPECIAL “EXPOSICIONES TEMPORALES”</button></h2>
            <div id="collapseOneCECULTAH-LA-15-2023" class="accordion-collapse collapse" aria-labelledby="headingOneCECULTAH-LA-15-2023" data-bs-parent="#accordionExample">
               <div class="accordion-body">
                  <ul class="list-group">
                     <li class="list-group-item">Convocatoria<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/procedimientos/CECULTAH-LA-15-2023/CECULTAH-LA-15-2023-Convocatoria.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                     <li class="list-group-item">Bases<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/procedimientos/CECULTAH-LA-15-2023/CECULTAH-LA-15-2023-Bases.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                     <li class="list-group-item">Junta de Aclaraciones<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/procedimientos/CECULTAH-LA-15-2023/CECULTAH-LA-15-2023_Junta de Aclaraciones.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                     <li class="list-group-item">Apertura de Propuestas<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/procedimientos/CECULTAH-LA-15-2023/CECULTAH-LA-15-2023-AperturaPropuestas.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                  </ul>
               </div>
            </div>
          </div>
          
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingOneCECULTAH-LA-16-2023"><button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOneCECULTAH-LA-16-2023" aria-expanded="false" aria-controls="collapseOneCECULTAH-LA-16-2023">CECULTAH-LA-16-2023 | EQUIPAMIENTO PARA FORTALECER ACTIVIDADES ARTÍSTICAS EN LOS CENTROS CULTURALES PARA ARTES PLÁSTICAS, MÚSICA, DANZA Y TEATRO</button></h2>
            <div id="collapseOneCECULTAH-LA-16-2023" class="accordion-collapse collapse" aria-labelledby="headingOneCECULTAH-LA-16-2023" data-bs-parent="#accordionExample">
               <div class="accordion-body">
                  <ul class="list-group">
                     <li class="list-group-item">Convocatoria<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/procedimientos/CECULTAH-LA-16-2023/CECULTAH-LA-16-2023-Convocatoria.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                     <li class="list-group-item">Bases<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/procedimientos/CECULTAH-LA-16-2023/CECULTAH-LA-16-2023-Bases.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                     <li class="list-group-item">Junta de Aclaraciones<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/procedimientos/CECULTAH-LA-16-2023/CECULTAH-LA-16-2023_Junta de Aclaraciones.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                     <li class="list-group-item">Apertura de Propuestas<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/procedimientos/CECULTAH-LA-16-2023/CECULTAH-LA-16-2023-AperturaPropuestas.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                   <li class="list-group-item">Fallo<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/procedimientos/CECULTAH-LA-16-2023/CECULTAH-LA-16-2023-ActaFallo.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                  </ul>
               </div>
            </div>
          </div>

          <div class="accordion-item">
            <h2 class="accordion-header" id="headingOneCECULTAH-LA-17-2023"><button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOneCECULTAH-LA-17-2023" aria-expanded="false" aria-controls="collapseOneCECULTAH-LA-17-2023">CECULTAH-LA-17-2023 | Adquisición de Instrumentos Musicales para la Banda Sinfónica del Estado de Hidalgo</button></h2>
            <div id="collapseOneCECULTAH-LA-17-2023" class="accordion-collapse collapse" aria-labelledby="headingOneCECULTAH-LA-17-2023" data-bs-parent="#accordionExample">
               <div class="accordion-body">
                  <ul class="list-group">
                     <li class="list-group-item">Convocatoria<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/procedimientos/CECULTAH-LA-17-2023/CECULTAH-LA-17-2023-Convocatoria.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                     <li class="list-group-item">Bases<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/procedimientos/CECULTAH-LA-17-2023/CECULTAH-LA-17-2023-Bases.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                     <li class="list-group-item">Junta de Aclaraciones<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/procedimientos/CECULTAH-LA-17-2023/CECULTAH-LA-17-2023-JuntaDeAclaraciones.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                     <li class="list-group-item">Apertura de Propuestas<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/procedimientos/CECULTAH-LA-17-2023/CECULTAH-LA-17-2023-AperturaPropuestas.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                  </ul>
               </div>
            </div>
          </div>

          <div class="accordion-item">
            <h2 class="accordion-header" id="headingOneCECULTAH-LA-14-2023"><button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOneCECULTAH-LA-14-2023" aria-expanded="false" aria-controls="collapseOneCECULTAH-LA-14-2023">CECULTAH-LA-14-2023 | Adquisición de instrumentos musicales para el Centro de las Artes de Hidalgo (2da)</button></h2>
            <div id="collapseOneCECULTAH-LA-14-2023" class="accordion-collapse collapse" aria-labelledby="headingOneCECULTAH-LA-14-2023" data-bs-parent="#accordionExample">
               <div class="accordion-body">
                  <ul class="list-group">
                     <li class="list-group-item">Convocatoria<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/procedimientos/CECULTAH-LA-14-2023/CECULTAH-LA-14-2023-Convocatoria.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                     <li class="list-group-item">Bases<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/procedimientos/CECULTAH-LA-14-2023/CECULTAH-LA-14-2023-Bases.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                     <li class="list-group-item">Junta de Aclaraciones<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/procedimientos/CECULTAH-LA-14-2023/CECULTAH-LA-14-2023-JuntaDeAclaraciones.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                     <li class="list-group-item">Apertura de Propuestas<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/procedimientos/CECULTAH-LA-14-2023/CECULTAH-LA-14-2023-AperturaPropuestas.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                     <li class="list-group-item">Fallo<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/procedimientos/CECULTAH-LA-14-2023/CECULTAH-LA-14-2023-ActaFallo.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                  </ul>
               </div>
            </div>
          </div>

          <div class="accordion-item">
            <h2 class="accordion-header" id="headingOneCECULTAH-LA-13-2023"><button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOneCECULTAH-LA-13-2023" aria-expanded="false" aria-controls="collapseOneCECULTAH-LA-13-2023">CECULTAH-LA-13-2023 | Adquisición de mobiliario y equipo para el CECULTAH (2da)</button></h2>
            <div id="collapseOneCECULTAH-LA-13-2023" class="accordion-collapse collapse" aria-labelledby="headingOneCECULTAH-LA-13-2023" data-bs-parent="#accordionExample">
               <div class="accordion-body">
                  <ul class="list-group">
                     <li class="list-group-item">Convocatoria<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/procedimientos/CECULTAH-LA-13-2023/CECULTAH-LA-13-2023-Convocatoria.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                     <li class="list-group-item">Bases<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/procedimientos/CECULTAH-LA-13-2023/CECULTAH-LA-13-2023-Bases.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                     <li class="list-group-item">Junta de Aclaraciones<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/procedimientos/CECULTAH-LA-13-2023/CECULTAH-LA-13-2023-JuntaDeAclaraciones.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                     <li class="list-group-item">Apertura de Propuestas<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/procedimientos/CECULTAH-LA-13-2023/CECULTAH-LA-13-2023-AperturaPropuestas.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                     <li class="list-group-item">Fallo<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/procedimientos/CECULTAH-LA-13-2023/CECULTAH-LA-13-2023-ActaFallo.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                  </ul>
               </div>
            </div>
          </div>

          <div class="accordion-item">
            <h2 class="accordion-header" id="headingOneCECULTAH-LA-12-2023"><button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOneCECULTAH-LA-12-2023" aria-expanded="false" aria-controls="collapseOneCECULTAH-LA-12-2023">CECULTAH-LA-12-2023 | Conservación y Mantenimiento Menor a Inmuebles del CECULTAH (Mantenimiento a piso de madera del Centro de las Artes del Estado de Hidalgo, Mantenimiento a piso de madera del Teatro Hidalgo “Bartolomé de Medina” y Mantenimiento a puerta de madera del Cuartel del Arte)(2da)</button></h2>
            <div id="collapseOneCECULTAH-LA-12-2023" class="accordion-collapse collapse" aria-labelledby="headingOneCECULTAH-LA-12-2023" data-bs-parent="#accordionExample">
               <div class="accordion-body">
                  <ul class="list-group">
                     <li class="list-group-item">Convocatoria<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/procedimientos/CECULTAH-LA-12-2023/CECULTAH-LA-12-2023-Convocatoria.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                     <li class="list-group-item">Bases<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/procedimientos/CECULTAH-LA-12-2023/CECULTAH-LA-12-2023-Bases.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                     <li class="list-group-item">Junta de Aclaraciones<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/procedimientos/CECULTAH-LA-12-2023/CECULTAH-LA-12-2023-JuntaDeAclaraciones.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                     <li class="list-group-item">Apertura de Propuestas<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/procedimientos/CECULTAH-LA-12-2023/CECULTAH-LA-12-2023-AperturaPropuestas.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                  </ul>
               </div>
            </div>
          </div>

           <div class="accordion-item">
              <h2 class="accordion-header" id="headingOneCECULTAH-LA-11-2023"><button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOneCECULTAH-LA-11-2023" aria-expanded="false" aria-controls="collapseOneCECULTAH-LA-11-2023">CECULTAH-LA-11-2023 | Adquisición de instrumentos musicales para el Centro de las Artes de Hidalgo</button></h2>
              <div id="collapseOneCECULTAH-LA-11-2023" class="accordion-collapse collapse" aria-labelledby="headingOneCECULTAH-LA-11-2023" data-bs-parent="#accordionExample">
                 <div class="accordion-body">
                    <ul class="list-group">         
                       <li class="list-group-item">Convocatoria<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/procedimientos/CECULTAH-LA-11-2023/CECULTAH-LA-11-2023-Convocatoria.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                       <li class="list-group-item">Bases<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/procedimientos/CECULTAH-LA-11-2023/CECULTAH-LA-11-2023-Bases.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>                       
                       <li class="list-group-item">Junta de Aclaraciones<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/procedimientos/CECULTAH-LA-11-2023/CECULTAH-LA-11-2023-JuntaDeAclaraciones.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                       <li class="list-group-item">Apertura de Propuestas<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/procedimientos/CECULTAH-LA-11-2023/CECULTAH-LA-11-2023-AperturaPropuestas.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    </ul>
                 </div>
              </div>
           </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOneCECULTAH-LA-10-2023"><button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOneCECULTAH-LA-10-2023" aria-expanded="false" aria-controls="collapseOneCECULTAH-LA-10-2023">CECULTAH-LA-10-2023 | Adquisición de mobiliario y equipo para el CECULTAH</button></h2>
              <div id="collapseOneCECULTAH-LA-10-2023" class="accordion-collapse collapse" aria-labelledby="headingOneCECULTAH-LA-10-2023" data-bs-parent="#accordionExample">
                 <div class="accordion-body">
                    <ul class="list-group">
                       <li class="list-group-item">Convocatoria<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/procedimientos/CECULTAH-LA-10-2023/CECULTAH-LA-10-2023-Convocatoria.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                       <li class="list-group-item">Bases<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/procedimientos/CECULTAH-LA-10-2023/CECULTAH-LA-10-2023-Bases.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                       <li class="list-group-item">Junta de Aclaraciones<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/procedimientos/CECULTAH-LA-10-2023/CECULTAH-LA-10-2023-JuntaDeAclaraciones.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                       <li class="list-group-item">Apertura de Propuestas<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/procedimientos/CECULTAH-LA-10-2023/CECULTAH-LA-10-2023-AperturaPropuestas.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    </ul>
                 </div>
              </div>
           </div>          

            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOneCECULTAH-LA-09-2023"><button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOneCECULTAH-LA-09-2023" aria-expanded="false" aria-controls="collapseOneCECULTAH-LA-09-2023">CECULTAH-LA-09-2023 | Conservación y Mantenimiento Menor a Inmuebles del CECULTAH (Mantenimiento a piso de madera del Centro de las Artes del Estado de Hidalgo, Mantenimiento a piso de madera del Teatro Hidalgo “Bartolomé de Medina” y Mantenimiento a puerta de madera del Cuartel del Arte)</button></h2>
              <div id="collapseOneCECULTAH-LA-09-2023" class="accordion-collapse collapse" aria-labelledby="headingOneCECULTAH-LA-09-2023" data-bs-parent="#accordionExample">
                 <div class="accordion-body">
                    <ul class="list-group">
                       <li class="list-group-item">Convocatoria<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/procedimientos/CECULTAH-LA-09-2023/CECULTAH-LA-09-2023-Convocatoria.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                       <li class="list-group-item">Bases<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/procedimientos/CECULTAH-LA-09-2023/CECULTAH-LA-09-2023-Bases.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                       <li class="list-group-item">Junta de Aclaraciones<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/procedimientos/CECULTAH-LA-09-2023/CECULTAH-LA-09-2023-JuntaDeAclaraciones.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                       <li class="list-group-item">Apertura de Propuestas<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/procedimientos/CECULTAH-LA-09-2023/CECULTAH-LA-09-2023-AperturaPropuestas.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    </ul>
                 </div>
              </div>
           </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOneCECULTAH-LA-08-2023"><button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOneCECULTAH-LA-08-2023" aria-expanded="false" aria-controls="collapseOneCECULTAH-LA-08-2023">CECULTAH-LA-08-2023 | Contratación del servicio de Mantenimiento de maquinaria y equipo de la Biblioteca Central del Estado “Ricardo Garibay”(2da)</button></h2>
              <div id="collapseOneCECULTAH-LA-08-2023" class="accordion-collapse collapse" aria-labelledby="headingOneCECULTAH-LA-08-2023" data-bs-parent="#accordionExample">
                 <div class="accordion-body">
                    <ul class="list-group">
                       <li class="list-group-item">Convocatoria<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/procedimientos/CECULTAH-LA-08-2023/CECULTAH-LA-08-2023-Convocatoria.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                       <li class="list-group-item">Bases<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/procedimientos/CECULTAH-LA-08-2023/CECULTAH-LA-08-2023-Bases.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                       <li class="list-group-item">Junta de Aclaraciones<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/procedimientos/CECULTAH-LA-08-2023/CECULTAH-LA-08-2023-JuntaDeAclaraciones.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                       <li class="list-group-item">Apertura de Propuestas<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/procedimientos/CECULTAH-LA-08-2023/CECULTAH-LA-08-2023-AperturaPropuestas.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    </ul>
                 </div>
              </div>
           </div>


            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOneCECULTAH-LA-07-2023"><button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOneCECULTAH-LA-07-2023" aria-expanded="false" aria-controls="collapseOneCECULTAH-LA-07-2023">CECULTAH-LA-07-2023 | Contratación del servicio de Mantenimiento de maquinaria y equipo de la Biblioteca Central del Estado “Ricardo Garibay”</button></h2>
              <div id="collapseOneCECULTAH-LA-07-2023" class="accordion-collapse collapse" aria-labelledby="headingOneCECULTAH-LA-07-2023" data-bs-parent="#accordionExample">
                 <div class="accordion-body">
                    <ul class="list-group">
                       <li class="list-group-item">Convocatoria<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/procedimientos/CECULTAH-LA-07-2023/CECULTAH-LA-07-2023-Convocatoria.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                       <li class="list-group-item">Bases<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/procedimientos/CECULTAH-LA-07-2023/CECULTAH-LA-07-2023-Bases.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                       <li class="list-group-item">Junta de Aclaraciones<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023//procedimientos/CECULTAH-LA-07-2023/CECULTAH-LA-07-2023-JuntaDeAclaraciones.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                       <li class="list-group-item">Apertura de Propuestas<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023//procedimientos/CECULTAH-LA-07-2023/CECULTAH-LA-07-2023-AperturaPropuestas.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                       <li class="list-group-item">Fallo<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/procedimientos/CECULTAH-LA-07-2023/CECULTAH-LA-07-2023-ActaFallo.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    </ul>
                 </div>
              </div>
           </div>            


            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOneCECULTAH-LA-05-2023"><button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOneCECULTAH-LA-05-2023" aria-expanded="false" aria-controls="collapseOneCECULTAH-LA-05-2023">CECULTAH-LA-05-2023 | Servicio de Limpieza y Manejo de Desechos (Contratación del Servicio de Fumigación a los inmuebles que se encuentran a cargo del CECULTAH)</button></h2>
              <div id="collapseOneCECULTAH-LA-05-2023" class="accordion-collapse collapse" aria-labelledby="headingOneCECULTAH-LA-05-2023" data-bs-parent="#accordionExample">
                 <div class="accordion-body">
                    <ul class="list-group">
                       <li class="list-group-item">Convocatoria<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/procedimientos/CECULTAH-LA-05-2023/CECULTAH-LA-05-2023-Convocatoria.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                       <li class="list-group-item">Bases<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/procedimientos/CECULTAH-LA-05-2023/CECULTAH-LA-05-2023-Bases.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                       <li class="list-group-item">Junta de Aclaraciones<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023//procedimientos/CECULTAH-LA-05-2023/CECULTAH-LA-05-2023-JuntaDeAclaraciones.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                       <li class="list-group-item">Acta de anulacion<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/procedimientos/CECULTAH-LA-05-2023/CECULTAH-LA-05-2023-ActaDeAnulacion.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    </ul>
                 </div>
              </div>
           </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOneCECULTAH-LA-03-2023"><button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOneCECULTAH-LA-03-2023" aria-expanded="false" aria-controls="collapseOneCECULTAH-LA-03-2023">CECULTAH-LA-03-2023 | Contratación de Seguros (2da)</button></h2>
              <div id="collapseOneCECULTAH-LA-03-2023" class="accordion-collapse collapse" aria-labelledby="headingOneCECULTAH-LA-03-20232023" data-bs-parent="#accordionExample">
                 <div class="accordion-body">
                    <ul class="list-group">
                       <li class="list-group-item">Convocatoria<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/procedimientos/CECULTAH-LA-03-2023/CECULTAH-LA-03-2023-Convocatoria.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                       <li class="list-group-item">Bases<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/procedimientos/CECULTAH-LA-03-2023/CECULTAH-LA-03-2023-Bases.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                       <li class="list-group-item">Junta de Aclaraciones<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/procedimientos/CECULTAH-LA-03-2023/CECULTAH-LA-03-2023_Junta_de_aclaraciones.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                       <li class="list-group-item">Relación de Bienes - Centro Cultural del Ferrocarril<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/procedimientos/CECULTAH-LA-02-2023/RELACION%20DE%20BIENES_CENTRO_CULTURAL_DEL_FERROCARRIL.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                       <li class="list-group-item">Relación de Bienes - Casa Felipe Ángeles<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/procedimientos/CECULTAH-LA-02-2023/RELACION_DE_BIENES_CASA_FELIPE_ANGELES.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                       <li class="list-group-item">Relación de Bienes - Centro Cultural de Zimapán<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/procedimientos/CECULTAH-LA-02-2023/RELACION_DE_BIENES_CENTRO_CULTURAL_DE_ZIMAPAN.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                       <li class="list-group-item">Relación de Bienes - Museo de la Cultura Huasteca<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/procedimientos/CECULTAH-LA-02-2023/RELACION_DE_BIENES_MUSEO_DE_LA_CULTURA_HUASTECA.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                       <li class="list-group-item">Apertura de Propuestas<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/procedimientos/CECULTAH-LA-03-2023/CECULTAH-LA-03-2023-AperturaPropuestas.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                       <li class="list-group-item">Fallo<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/procedimientos/CECULTAH-LA-03-2023/CECULTAH-LA-03-2023-ActaFallo.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    </ul>
                 </div>
              </div>
           </div>

           <div class="accordion-item">
              <h2 class="accordion-header" id="headingOneCECULTAH-LA-02-2023"><button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOneCECULTAH-LA-02-2023" aria-expanded="false" aria-controls="collapseOneCECULTAH-LA-02-2023">CECULTAH-LA-02-2023 | Contratación de Seguros</button></h2>
              <div id="collapseOneCECULTAH-LA-02-2023" class="accordion-collapse collapse" aria-labelledby="headingOneCECULTAH-LA-02-20232023" data-bs-parent="#accordionExample">
                 <div class="accordion-body">
                    <ul class="list-group">
                       <li class="list-group-item">Convocatoria<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/procedimientos/CECULTAH-LA-02-2023/CECULTAH-LA-02-2023_Convocatoria.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                       <li class="list-group-item">Bases<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/procedimientos/CECULTAH-LA-02-2023/CECULTAH-LA-02-2023_Bases.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                       <li class="list-group-item">Junta de Aclaraciones<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/procedimientos/CECULTAH-LA-02-2023/CECULTAH-LA-02-2023_JuntaDeAclaraciones.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                       <li class="list-group-item">Respuestas<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/procedimientos/CECULTAH-LA-02-2023/CECULTAH-LA-02-2023_Respuestas.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                       <li class="list-group-item">Relación de Bienes - Centro Cultural del Ferrocarril<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/procedimientos/CECULTAH-LA-02-2023/RELACION%20DE%20BIENES_CENTRO_CULTURAL_DEL_FERROCARRIL.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                       <li class="list-group-item">Relación de Bienes - Casa Felipe Ángeles<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/procedimientos/CECULTAH-LA-02-2023/RELACION_DE_BIENES_CASA_FELIPE_ANGELES.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                       <li class="list-group-item">Relación de Bienes - Centro Cultural de Zimapán<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/procedimientos/CECULTAH-LA-02-2023/RELACION_DE_BIENES_CENTRO_CULTURAL_DE_ZIMAPAN.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                       <li class="list-group-item">Relación de Bienes - Museo de la Cultura Huasteca<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/procedimientos/CECULTAH-LA-02-2023/RELACION_DE_BIENES_MUSEO_DE_LA_CULTURA_HUASTECA.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                       <li class="list-group-item">Apertura de Propuestas<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/procedimientos/CECULTAH-LA-02-2023/CECULTAH.LA.02.2023_ActaAperturaProposiciones.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    </ul>
                 </div>
              </div>
           </div>

           <div class="accordion-item">
              <h2 class="accordion-header" id="headingOneCECULTAH-LA-01-2023"><button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOneCECULTAH-LA-01-2023" aria-expanded="false" aria-controls="collapseOneCECULTAH-LA-01-2023">CECULTAH-LA-01-2023 | Adquisición de material de limpieza</button></h2>
              <div id="collapseOneCECULTAH-LA-01-2023" class="accordion-collapse collapse" aria-labelledby="headingOneCECULTAH-LA-01-2023" data-bs-parent="#accordionExample">
                 <div class="accordion-body">
                    <ul class="list-group">
                       <li class="list-group-item">Convocatoria<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/procedimientos/CECULTAH-LA-01-2023/CECULTAH-LA-01-2023_Convocatoria.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                       <li class="list-group-item">Bases<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/procedimientos/CECULTAH-LA-01-2023/CECULTAH-LA-01-2023_Bases.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                       <li class="list-group-item">Junta de Aclaraciones<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/procedimientos/CECULTAH-LA-01-2023/CECULTAH-LA-01-2023_JuntaDeAclaraciones.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                       <li class="list-group-item">Apertura de Propuestas<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/procedimientos/CECULTAH-LA-01-2023/CECULTAH-LA-01-2023_PresentacionyAperturaDePropuestas.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                       <li class="list-group-item">Fallo<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/procedimientos/CECULTAH-LA-01-2023/CECULTAH-LA-01-2023_ActaDeFallo.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    </ul>
                 </div>
              </div>
           </div>*/}

           
           {/* ejemplo de acordeon

           <div class="accordion-item">
              <h2 class="accordion-header" id="headingOne2021"><button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne2021" aria-expanded="false" aria-controls="collapseOne2021">2021</button></h2>
              <div id="collapseOne2021" class="accordion-collapse collapse" aria-labelledby="headingOne2021" data-bs-parent="#accordionExample">
                 <div class="accordion-body">
                    <ul class="list-group">
                       <li class="list-group-item">Primer Trimestre<a class="card_trimestre bg-secondary" href="#" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.zip</i></a></li>
                       <li class="list-group-item">Segundo Trimestre<a class="card_trimestre bg-secondary" href="#" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.zip</i></a></li>
                       <li class="list-group-item">Tercer Trimestre<a class="card_trimestre bg-secondary" href="#" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.zip</i></a></li>
                       <li class="list-group-item">Cuarto Trimestre<a class="card_trimestre bg-secondary" href="#" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.zip</i></a></li>
                    </ul>
                 </div>
              </div>
           </div>

           */}

          </div>

        </div>
    </div>
  )
}

export default Licitaciones